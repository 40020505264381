
  import { Component, Prop, Mixins, Emit, Watch } from "vue-property-decorator";
  import { HasDesktopPart } from "@/mixins/properties/has-desktop-part";

  @Component
  export default class PagePropertyPartDesktopModalityNote extends Mixins(HasDesktopPart) {
    @Prop({ default: null }) value!: string | string[] | null;

    localValue: string[] = [""];

    addOtherCondition() {
      this.localValue.push("");
    }

    removeOtherCondition(index: number) {
      this.localValue.splice(index, 1);

      this.handleInput();
    }

    @Emit("input")
    handleInput(): string[] {
      return this.localValue;
    }

    @Watch("value", { immediate: true })
    valueChanged(newValue: string | string[] | null) {
      if (typeof newValue === "string") {
        this.localValue = [newValue];
      } else if (Array.isArray(newValue)) {
        this.localValue = newValue;
      } else {
        this.localValue = [""];
      }
    }
  }
